import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';

import React, {useEffect, useState} from 'react';

import { IReportData } from '../shared/interfaces';
import { MatrixService } from "../services/matrixservice";
import { MatrixSettings } from "../models/matrixSettings";
import { SpinnerContainer } from "./shared/SpinnerContainer";
import axios from 'axios';

const matrixService = new MatrixService();
const matrixSettings = new MatrixSettings();

const apiURL = 	matrixSettings.awsUrl + "/get-dash-with-rls/"

const embedDashboard = async (dash:IReportData) => {

    const _axiosHeaders = await matrixService.getHeaders();

    const command = apiURL;

    let postBody = {
        dashboardId: dash.DashboardId
      }

    const response = await axios.post(
      command, 
      postBody, {"headers": _axiosHeaders}
      ).catch((error) => {
        console.log("Error on embed dash retrieve: ", error)
        return {
          data:error.response.data,
          status: error.response.status
        }
      });
    
    const containerDiv = document.getElementById('embeddingContainer') ?? new HTMLElement();

    if(response.status === 200) {

      let parameterOption:any = [];

      if(Object.hasOwn(dash, 'Parameters')) {
        const user:any = await matrixService.getCurrentUser();
        if(dash.Parameters && dash.Parameters.length > 0){
          dash.Parameters.forEach((param:any)=>{
            if (param.length > 0) {
              const _values = user[param];
              parameterOption.push({Name: param, Values: [_values]});
            }
          }); 
        }
      }
      
      const embeddingContext = await QuickSightEmbedding.createEmbeddingContext({
        onChange: (changeEvent: any, metadata: any) => {
          console.log('Context received a change', changeEvent, metadata);
        }
      });

      const frameOptions = {
          url: response.data,
          container: containerDiv,
          scrolling: 'yes',
          width: '100%',
          printEnabled: 'yes'
      };

      const contentOptions = {
        toolbarOptions: {
          export: true,
          undoRedo: true,
          reset: true
        }
      };

      if(parameterOption.length > 0) {
        console.log("Parameter Option: ", parameterOption);
        Object.defineProperty(contentOptions, "parameters", { value: parameterOption, enumerable: true });
        console.log("Content Options: ", contentOptions);
      }

      if(dash.QTopic) {
        const qOptions = {
            hideTopicName: false, 
            allowTopicSelection: true
        };

        await embeddingContext.embedQSearchBar(frameOptions, qOptions);
      } else {
        await embeddingContext.embedDashboard(frameOptions, contentOptions);
      }
      
    } else {
      containerDiv.innerHTML = `<div>${response.data}</div>`;
    }
}

export type Props = {
  dash: IReportData
}

function Dashboard(props: Props) {

  const [dashLoaded, setDashLoaded] = useState(false);
  const dash = props.dash;

  useEffect(() => {
    
    const getDash = async () => {
      setDashLoaded(false);

      await embedDashboard(dash);

      setDashLoaded(true);
    } 

    getDash();
  
  }, [dash]);
  
  return (
    <div>
      {!dashLoaded &&

        <div className="tw-h-screen">
          <div className= "tw-h-4/6 tw-grid tw-w-full tw-place-content-center">
            <SpinnerContainer />
          </div>
        </div>

      }
      <div id="embeddingContainer" style={{overflow: "auto", height: "80vh", minWidth: "100%"}}>
      </div>
    </div>
  );

  
}

export default Dashboard;